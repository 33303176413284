<template>
  <a-dropdown :trigger="['click']" placement="bottomLeft" @visibleChange="addCount">
    <div :class="$style.dropdown">
      <a-avatar shape="square" icon="user" :src="user.image" size="large" :class="$style.avatar" />
    </div>
    <a-menu slot="overlay" v-if="user">
      <a-menu-item>
        <div>
          <strong>Приветствую, {{ user.first_name }} {{ user.last_name }}</strong>
        </div>
        <div>
          <strong class="mr-1">Роль:</strong>
          {{ user.role || '—' }}
        </div>
      </a-menu-item>
      <a-menu-divider />
      <a-menu-item>
        <div>
          <strong class="mr-1">{{ $t('topBar.profileMenu.email') }}:</strong>
          {{ user.email || '—' }}
        </div>
        <div>
          <strong class="mr-1">{{ $t('topBar.profileMenu.phone') }}:</strong> —
          {{ user.phone || '—' }}
        </div>
      </a-menu-item>
      <a-menu-divider />
      <a-menu-item>
        <a href="javascript: void(0);" @click="$router.push({ path: '/profile' })">
          <i class="fe fe-user mr-2"></i>
          Редактировать профиль
        </a>
      </a-menu-item>
      <a-menu-divider />
      <a-menu-item>
        <a href="javascript: void(0);" @click="logout">
          <i class="fe fe-log-out mr-2"></i>
          Выйти
        </a>
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data: function () {
    return {
      count: 7,
    }
  },

  computed: {
    ...mapState({ user: state => state.user.user }),
    loading() {
      return this.$store.state.user.loading
    },
  },

  methods: {
    addCount() {
      this.count++
    },
    async logout() {
      await this.$store.dispatch('user/LOGOUT')
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
