import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({ role: state => state.user.user ? state.user.user.role : {} }),
    isNotCreated() {
      return this.$route.params.id !== '0'
    },
    isPreview() {
      return !!this.$route.query.is_preview
    },
    isManager() {
      return this.role === 'content_manager'
    },
  },

  methods: {
    async removeUser(id) {
      try {
        await this.$services.delete(`admin/users/${id}`)
        await this.getList()
      } catch (e) {
        console.log(e)
      }
    },

    handleChangeList({ fileList }) {
      this.fileListMain = fileList
    },
  },
}
