import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import VueCookies from 'vue-cookies'

import * as firebase from '@/services/firebase'
import * as jwt from '@/services/jwt'
import apiClient from '@/services/axios'

const mapAuthProviders = {
  firebase: {
    login: firebase.login,
    register: firebase.register,
    currentAccount: firebase.currentAccount,
    logout: firebase.logout,
  },
  jwt: {
    login: jwt.login,
    register: jwt.register,
    currentAccount: jwt.currentAccount,
    logout: jwt.logout,
  },
}

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    user: null,
    loading: false,
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    LOGIN({ commit }, response) {
      commit('SET_STATE', {
        user: response.user,
        loading: response.loading,
      })
      Vue.prototype.$notification.success({
        message: 'Logged In',
        description: 'You have successfully logged in!',
      })
      router.push('/')
    },
    REGISTER({ commit, dispatch, rootState }, { payload }) {
      const { email, password, name } = payload
      commit('SET_STATE', {
        loading: true,
      })

      const register = mapAuthProviders[rootState.settings.authProvider].register
      register(email, password, name).then(success => {
        if (success) {
          dispatch('LOAD_CURRENT_ACCOUNT')
          Vue.prototype.$notification.success({
            message: 'Succesful Registered',
            description: 'You have successfully registered!',
          })
        }
        if (!success) {
          commit('SET_STATE', {
            loading: false,
          })
        }
      })
    },
    LOAD_CURRENT_ACCOUNT({ commit }, user) {
      commit('SET_STATE', {
        user,
      })
    },
    async LOGOUT({ commit }) {
      await apiClient.post('/auth/logout')
      VueCookies.remove('token')
      commit('SET_STATE', {
        user: null,
        loading: false,
      })
      await router.push('/auth/login')
    },
  },
  getters: {
    user: state => state,
  },
}
