import Vue from 'vue'
import moment from 'moment'

Vue.filter('getFormattedValue', (value) => {
  return value || '-'
})

Vue.filter('getFormattedDate', (date) => {
  return moment(date).format('HH:mm DD.MM.YYYY')
})

Vue.filter('getFormattedConsumption', (value) => {
  return value / 100 || '-'
})
