import axios from 'axios'
import { notification } from 'ant-design-vue'
import VueCookies from 'vue-cookies'
import Vue from 'vue'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  // timeout: 1000,
  // headers: { 'X-Custom-Header': 'foobar' }
})

apiClient.interceptors.request.use(request => {
  const accessToken = VueCookies.get('token') ? VueCookies.get('token') : false
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
  }
  return request
})

apiClient.interceptors.response.use(function (response) {
  if (response.config.method === 'delete') {
    notification.success({
      message: 'Удаление прошло успешно',
    })
  }
  if (response.config.method === 'post' && !response.config.url.includes('login') && !response.config.url.includes('logout')) {
    notification.success({
      message: 'Добавление прошло успешно',
    })
  }
  if (response.config.method === 'put') {
    notification.success({
      message: 'Изменение прошло успешно',
    })
  }
  return response
}, () => {
  notification.error({
    message: 'Что-то пошло не так',
  })
})

Vue.prototype.$services = apiClient

export default apiClient
