export const getMenuData = [
  {
    title: 'Статистика',
    key: 'Статистика',
    icon: 'fe fe-activity',
    url: '/statistics',
  },

  {
    title: 'Справочники',
    key: 'Справочники',
    icon: 'fe fe-sliders',
    // roles: ['admin'], // set user roles with access to this route
    count: 19,
    children: [
      {
        title: 'Типы',
        key: 'Типы',
        url: '/handbook/car_types',
      },
      {
        title: 'Марки',
        key: 'Марки',
        url: '/handbook/car_marks',
      },
      {
        title: 'Модели',
        key: 'Модели',
        url: '/handbook/car_models',
      },
      {
        title: 'Страны',
        key: 'Страны',
        url: '/handbook/countries',
      },
      {
        title: 'Регионы',
        key: 'Регионы',
        url: '/handbook/regions',
      },
      {
        title: 'Города',
        key: 'Города',
        url: '/handbook/cities',
      },
      {
        title: 'Коробки передач',
        key: 'Коробки передач',
        url: '/handbook/transmissions',
      },
      {
        title: 'Типы топлива',
        key: 'Типы топлива',
        url: '/handbook/fuels',
      },
      {
        title: 'Тип привода',
        key: 'Тип привода',
        url: '/handbook/drive_unit',
      },
      {
        title: 'Объемы ДВС',
        key: 'Объемы ДВС',
        url: '/handbook/engine_capacities',
      },
      {
        title: 'Мощности ДВС',
        key: 'Мощности ДВС',
        url: '/handbook/engine_powers',
      },
      {
        title: 'Количество дверей',
        key: 'Количество дверей',
        url: '/handbook/doors',
      },
      {
        title: 'Цвета',
        key: 'Цвета',
        url: '/handbook/colors',
      },
      {
        title: 'Типы состояний',
        key: 'Типы состояний',
        url: '/handbook/car_condition',
      },
      {
        title: 'Типы безопасностей',
        key: 'Типы безопасностей',
        url: '/handbook/car_securities',
      },
      {
        title: 'Мультимедиа',
        key: 'Мультимедиа',
        url: '/handbook/car_multimedia',
      },
      {
        title: 'Комфорт',
        key: 'Комфорт',
        url: '/handbook/car_comforts',
      },
      {
        title: 'Другое',
        key: 'Другое',
        url: '/handbook/car_anothers',
      },
      {
        title: 'Типы кузовов',
        key: 'Типы кузовов',
        url: '/handbook/car_series',
      },
    ],
  },

  {
    title: 'Настройка',
    key: 'Настройка',
    icon: 'fe fe-settings',
    // roles: ['admin'], // set user roles with access to this route
    count: 7,
    children: [
      {
        title: 'Шапка',
        key: 'Шапка',
        url: '/content/header',
      },
      {
        title: 'Подвал',
        key: 'Подвал',
        url: '/content/footer',
      },
      {
        title: 'Банера',
        key: 'Банера',
        url: '/content/banners',
      },
      {
        title: 'Настройка сайта',
        key: 'Настройка сайта',
        url: '/content/main_settings',
      },
      {
        title: 'Настройка изображений',
        key: 'Настройка изображений',
        url: '/content/image_settings',
      },
      {
        title: 'Тарифы публикаций',
        key: 'Тарифы публикаций',
        url: '/functional/publications',
      },
      {
        title: 'Тарифы продвижения',
        key: 'Тарифы продвижения',
        url: '/functional/promotions',
      },
      {
        title: 'Информация об организации',
        key: 'Информация об организации',
        url: '/functional/organization-info',
      },
    ],
  },

  {
    title: 'Контент',
    key: 'Контент',
    icon: 'fe fe-align-center',
    // roles: ['admin'], // set user roles with access to this route
    count: 3,
    children: [
      {
        title: 'Новости',
        key: 'Новости',
        url: '/content/news',
      },
      {
        title: 'Страницы сайта',
        key: 'Страницы сайта',
        url: '/content/all_pages',
      },
      {
        title: 'Контентные страницы',
        key: 'Контентные страницы',
        url: '/content/pages',
      },
    ],
  },

  {
    title: 'Пользователи',
    key: 'Пользователи',
    icon: 'fe fe-users',
    // roles: ['admin'], // set user roles with access to this route
    count: 2,
    children: [
      {
        title: 'Клиенты',
        key: 'Клиенты',
        url: '/users/clients',
      },
      {
        title: 'Администраторы',
        key: 'Администраторы',
        url: '/users/admins',
        condition: ['super_admin', 'admin'],
      },
    ],
  },

  {
    title: 'Функционал',
    key: 'Функционал',
    icon: 'fe fe-cpu',
    // roles: ['admin'], // set user roles with access to this route
    count: 6,
    children: [
      {
        title: 'Жалобы',
        key: 'Жалобы',
        url: '/functional/complaints',
      },
      {
        title: 'Отзывы фильтра',
        key: 'Отзывы фильтра',
        url: '/functional/usability',
      },
      {
        title: 'Рассылки',
        key: 'Рассылки',
        url: '/functional/mailings',
      },
      {
        title: 'Проверки авто',
        key: 'Проверки авто',
        url: '/functional/checks',
      },
      /* {
        title: 'Подписки',
        key: 'Подписки',
        url: '/functional/subscriptions',
      }, */
      {
        title: 'Объявления',
        key: 'Объявления',
        url: '/content/products',
      },
    ],
  },
]
