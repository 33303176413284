<template>
  <div :class="$style.topbar">
    <div class="mr-4" v-if="false">
      <cui-fav-pages />
    </div>
    <div class="mr-auto" v-if="false">
      <cui-search />
    </div>
    <div class="mr-4 d-none d-md-block" v-if="false">
      <cui-issues-history />
    </div>
    <div class="mb-0 mr-auto d-xl-block d-none" v-if="false">
      <cui-project-management />
    </div>
    <div class="mr-4 d-none d-sm-block" v-if="false">
      <cui-language-switcher />
    </div>
    <div class="mr-4 d-none d-sm-block" v-if="false">
      <cui-actions />
    </div>
    <div class="ml-auto">
      <cui-user-menu />
    </div>
  </div>
</template>

<script>
import CuiIssuesHistory from './IssuesHistory'
import CuiProjectManagement from './ProjectManagement'
import CuiSearch from './Search'
import CuiLanguageSwitcher from './LanguageSwitcher'
import CuiActions from './Actions'
import CuiUserMenu from './UserMenu'
import CuiFavPages from './FavPages'

export default {
  components: {
    CuiIssuesHistory,
    CuiProjectManagement,
    CuiSearch,
    CuiLanguageSwitcher,
    CuiActions,
    CuiUserMenu,
    CuiFavPages,
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
